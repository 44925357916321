<template>
  <div>
    <div class="order scrollbar">
      <div v-if="loading">
        <div v-for="index in 3" :key="index">
          <Skeleton width="10rem" height="1.2rem" class="order-time mt-10" />
          <Skeleton width="15rem" height="1.2rem" />
        </div>
        <Skeleton width="10rem" height="3rem" class="mt-12" />
      </div>
      <div v-else>
        <div>
          <p class="text-sm md:font-semibold text-lightPurple-2">
            Order #{{ purchaseDetail.orderCode }}
          </p>
        </div>
        <div class="">
          <div class="order-time mt-10">
            <p class="text-sm text-black md:mb-1">Transaction</p>
            <h3 class="text-black md:text-lg font-semibold">
              <span
                :class="{
                  pending: purchaseDetail.status === 'PENDING',
                  closed: purchaseDetail.status === 'CLOSED',
                  success: purchaseDetail.status === 'SUCCESSFUL',
                  processing: purchaseDetail.status === 'PAYMENT_IN_PROGRESS',
                  failed: purchaseDetail.status === 'FAILED',
                  refunded: purchaseDetail.status === 'REFUNDED'
                }"
                >{{ purchaseDetail.status | capitalize }}</span
              >
            </h3>
          </div>
          <div
            class="mt-4"
            v-if="
              purchaseDetail.customerFirstName ||
                purchaseDetail.customerLastName
            "
          >
            <p class="text-sm text-black md:mb-1">Customer name</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.customerFirstName | capitalize }}
              {{ purchaseDetail.customerLastName | capitalize }}
            </h3>
          </div>
          <div class="mt-4" v-else-if="purchaseDetail.anonymous === true">
            <p class="text-sm text-black md:mb-1">Customer</p>
            <h3 class="text-black md:text-lg font-semibold">
              Anonymous user
            </h3>
          </div>
          <div class="mt-4" v-if="purchaseDetail.customerEmailAddress">
            <p class="text-sm text-black md:mb-1">Customer email</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.customerEmailAddress | capitalize }}
            </h3>
          </div>
          <!-- <div class="order-time mt-6" v-if="purchaseDetail.createdAt !== null">
            <p class="text-sm text-black md:mb-1">Date/time of creation</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{
                dayjs(purchaseDetail.createdAt).format("D MMM YYYY  |  hh.mm a")
              }}
            </h3>
          </div> -->
          <div
            class="payment-method mt-6"
            v-if="purchaseDetail.customerHandle !== null"
          >
            <p class="text-sm text-black md:mb-1">Customer name</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.customerHandle }}
            </h3>
          </div>
          <div class="payment-method mt-6">
            <p class="text-sm text-black mb-1">Amount of purchase</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.amountOfOrder | formatMoney }}
            </h3>
          </div>
          <div
            class="payment-method mt-6"
            v-if="purchaseDetail.paymentPlan !== null"
          >
            <p class="text-sm text-black mb-1">Payment plan</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.paymentPlan }}
            </h3>
          </div>
          <div
            class="payment-method mt-6"
            v-if="purchaseDetail.feeChargedToMerchant !== null"
          >
            <p class="text-sm text-black mb-1">Fee Charged</p>
            <h3 class="text-black md:text-lg font-semibold">
              {{ purchaseDetail.feeChargedToMerchant | formatMoney }}
            </h3>
          </div>

          <div
            class="order-time mt-6"
            v-if="purchaseDetail.completedAt !== null"
          >
            <p class="text-sm text-black md:mb-1">
              Date/time of purchase
            </p>
            <h3 class="text-black md:text-lg font-semibold">
              {{
                dayjs(purchaseDetail.completedAt).format(
                  "D MMM YYYY  |  hh.mm a"
                )
              }}
            </h3>
          </div>
        </div>
        <div
          class="buttons flex items-center -mt-10 justify-end"
          v-if="purchaseDetail.status === 'SUCCESSFUL'"
        >
          <Button
            :loading="refundLoading"
            text="Issue refund"
            @click="activateRefund"
            p="py-3 px-4"
            width="md:w-44 w-30"
            fontSize="text-sm"
          />
          <!-- <p
            class="px-10 text-brandPurple cursor-pointer"
            @click="activateRefund"
          >
            Issue refund
          </p> -->
          <!-- <Button
            text="Generate receipt"
            background="bg-white"
            outline
            p="py-3 px-4"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VIEW_ONE_PURCHASE } from "@/utils/api/purchase.js";
import { mapState } from "vuex";
import dayjs from "dayjs";
import Hr from "@/UI/Hr";
import Button from "@/UI/Button";
import copy from "@/mixins/copy";
export default {
  mixins: [copy],
  components: {
    Hr,
    Button
  },
  props: {
    purchaseDetails: {
      type: Object,
      default: () => {},
      required: true
    },
    params: {
      type: Object,
      default: () => {},
      required: false
    },
    refundLoading: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data: () => ({
    loading: false,
    purchaseDetail: {}
  }),
  computed: {
    ...mapState({
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      liveMode: state => state?.auth?.liveMode
    }),
    dayjs() {
      return dayjs;
    }
  },

  beforeMount() {
    this.showOnePurchase();
  },
  methods: {
    showOnePurchase() {
      this.loading = true;
      VIEW_ONE_PURCHASE({
        id: this.purchaseDetails.id,
        liveMode: this.liveMode
      })
        .then(res => {
          this.purchaseDetail = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    activateRefund() {
      this.$emit("refund", this.purchaseDetail);
    }
  }
};
</script>

<style scoped>
.order {
  min-height: 400px;
}
.order-link {
  color: #727272;
}
</style>
