var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"purchase-container mt-4"},[_c('div',{staticClass:"w-full"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',[(!_vm.loading && !_vm.getPurchases.length)?_c('EmptyState',{staticClass:"empty mt-16",attrs:{"description":"No purchase to display"}}):_vm._e(),_c('table',{staticClass:"w-full table-auto",attrs:{"id":"template-table"}},[(_vm.getPurchases.length)?_c('thead',[_c('tr',[_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Order Code")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Action")])])]):_vm._e(),(_vm.loading)?_c('Loader'):_c('tbody',_vm._l((_vm.getPurchases),function(purchase){return _c('tr',{key:purchase.id,staticClass:"cursor-pointer"},[_c('td',{staticClass:"flex items-center"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("capitalize")(purchase.title),35))+" ")]),_c('td',[_c('span',{staticClass:"text-grey"},[_vm._v("#")]),_vm._v(_vm._s(purchase.orderCode))]),_c('td',[_vm._v(_vm._s(_vm._f("formatMoney")(purchase.amountOfOrder)))]),_c('td',[_c('span',{class:{
                    pending: purchase.status === 'PENDING',
                    closed: purchase.status === 'CLOSED',
                    success: purchase.status === 'SUCCESSFUL',
                    processing: purchase.status === 'PAYMENT_IN_PROGRESS',
                    failed: purchase.status === 'FAILED',
                    refunded: purchase.status === 'REFUNDED',
                  }},[_vm._v(_vm._s(_vm._f("capitalize")(purchase.status)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.dayjs(purchase.createdAt).format("D MMM YYYY | hh.mm a"))+" ")]),_c('td',[_c('Button',{staticClass:"mt-1",attrs:{"text":"View","background":"bg-lightPurple","color":"text-brandPurple","p":"py-1.5 px-6","fontSize":"text-xs","radius":"rounded-2xl","fontWeight":"font-semibold"},on:{"click":function($event){return _vm.$emit('singlePurchase', purchase)}}})],1)])}),0)],1)],1)]),_c('Pagination',{attrs:{"handleMore":_vm.handleMore,"loading":_vm.paginateLoading},on:{"paginate":function($event){return _vm.$emit('paginate')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }