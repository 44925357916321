<template>
  <div>
    <!-- <SearchInput placeholder="Amount, merchant, title, status" p="p-3" /> -->
    <Card>
      <div class="order-container">
        <div class="flex justify-between items-center">
          <h3 class="font-semibold" v-if="!loading">Purchases</h3>
          <Skeleton width="5rem" height="1rem" v-else />
          <p
            class="text-sm warning font-semibold cursor-pointer"
            @click="handleShowFilter"
            v-if="!loading"
          >
            Filter list
          </p>
          <Skeleton width="3rem" height="0.8rem" v-else />
        </div>
        <hr class="bg-lightPurple my-2 border-lightPurple" />
        <!-- loading -->
        <transition name="fade" mode="out-in">
          <div v-if="loading" key="loading">
            <div v-for="i in 10" :key="i">
              <div class="payment-table">
                <div class="flex items-center">
                  <div class="w-full">
                    <Skeleton width="50%" height="0.8rem" />
                    <Skeleton width="80%" height="0.8rem" />
                    <Skeleton width="50%" height="0.8rem" />
                  </div>
                </div>
                <div class="flex justify-end align-center">
                  <div class="w-full flex flex-col items-end">
                    <Skeleton width="5rem" height="0.8rem" />
                    <Skeleton width="4rem" height="0.8rem" />
                  </div>
                </div>
              </div>
              <hr class="bg-lightPurple my-2 border-lightPurple" />
            </div>
          </div>
          <div v-if="!loading" key="available">
            <EmptyState
              v-if="!getPurchases.length"
              description="No purchase to display"
              class="mt-16"
            />

            <div
              v-for="purchase in getPurchases"
              :key="purchase.id"
              @click="$emit('singlePurchase', purchase)"
              v-else
            >
              <div class="flex justify-between py-1">
                <div>
                  <p class="text-sm text-grey">
                    {{
                      dayjs(purchase.createdAt).format("D MMM YYYY  |  hh.mm a")
                    }}
                  </p>
                  <h2 class="font-semibold">
                    {{ purchase.title | capitalize }}
                  </h2>
                  <p
                    class="text-sm"
                    :class="{
                      pending: purchase.status === 'PENDING',
                      closed: purchase.status === 'CLOSED',
                      success: purchase.status === 'SUCCESSFUL',
                      processing: purchase.status === 'PAYMENT_IN_PROGRESS',
                      failed: purchase.status === 'FAILED',
                      refunded: purchase.status === 'REFUNDED',
                    }"
                  >
                    {{ purchase.status | capitalize }}
                  </p>
                </div>
                <div class="text-right">
                  <p class="text-sm text-lightPurple-2">
                    #{{ purchase.orderCode }}
                  </p>
                  <h2 class="font-semibold">
                    {{ purchase.amountOfOrder | formatMoney }}
                  </h2>
                </div>
              </div>
              <hr class="bg-lightPurple my-2 border-lightPurple" />
            </div>
            <!-- <hr class="bg-lightPurple my-2 border-lightPurple" /> -->
          </div>
        </transition>

        <Pagination
          :handleMore="handleMore"
          :loading="paginateLoading"
          @paginate="$emit('paginate')"
        />
      </div>
    </Card>
    <!-- modal for mobile filter -->
    <Modal
      :display="showFilter"
      title="Filter details"
      @close="showFilter = false"
    >
      <div class="flex justify-between items-center">
        <p class="text-black font-semibold">Select date</p>
        <DatePicker
          class="mr-2"
          v-model="range"
          is-range
          :model-config="modelConfig"
          :masks="masks"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <Card
              class="flex w-44 items-center border searchContainer"
              p="px-2 py-1"
            >
              <button class="p-2 focus:outline-none" @click="togglePopover()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                >
                  <path
                    d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                    fill="#9D9D9D"
                    stroke="#9D9D9D"
                    stroke-width=".1"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <p
                v-if="inputValue.start === null"
                @click="togglePopover()"
                class="text-grey"
              >
                Date filter
              </p>
              <p v-else @click="togglePopover()" class="text-sm text-grey">
                {{ inputValue.start }} - {{ inputValue.end }}
              </p>
            </Card>
          </template>
        </DatePicker>
      </div>
      <Hr class="my-6" />
      <div class="flex items-center justify-between">
        <p class="text-black font-semibold">Status</p>
        <Card class="searchContainer w-44 mr-2" p="px-2">
          <Select
            type="filter"
            v-model="parameter.status"
            placeholder="All"
            :reduce="(type) => type.value"
            :options="statusOptions"
          />
        </Card>
      </div>
      <Hr class="my-6" />
      <div class="flex justify-between items-center">
        <p class="text-black font-semibold">Order Code</p>
        <Card class="w-48" p="p-2">
          <Search
            v-model="parameter.orderId"
            class="py-2"
            placeholder="Search"
          />
        </Card>
      </div>
      <Button
        shadow
        width="w-full"
        class="mt-8"
        text="Apply filter"
        @click="applyFilter"
      />
    </Modal>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { DatePicker } from "v-calendar";
import Hr from "@/UI/Hr";
import Select from "@/UI/Select";
import Button from "@/UI/Button";
import EmptyState from "@/UI/EmptyState";
import Pagination from "@/UI/Pagination";
import Search from "@/UI/SearchInput";
export default {
  components: {
    Modal: () => import("@/UI/Modal"),
    DatePicker,
    Hr,
    Select,
    Button,
    EmptyState,
    Pagination,
    Search,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    getPurchases: {
      type: Array,
      default: () => [],
      required: true,
    },
    statusOptions: {
      type: Array,
      default: () => [],
      required: false,
    },
    params: {
      type: Object,
      default: () => {},
      required: true,
    },
    paginateLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    handleMore: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    showFilter: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    parameter: {
      orderId: "",
      page: 0,
      pageSize: 20,
      createdAtFrom: "",
      createdAtTo: "",
      status: "",
    },
  }),
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  methods: {
    applyFilter() {
      this.showFilter = false;
      this.parameter.page = 0;
      this.parameter.createdAtFrom = this.range.start;
      this.parameter.createdAtTo = this.range.end;
      this.$emit("filter", this.parameter);
    },
    handleShowFilter() {
      this.parameter = this.params;
      this.showFilter = true;
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.payment-table {
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-gap: 5px;
}
.order-container {
  min-height: calc(100vh - 12rem);
}
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  /* width: 12rem; */
}
</style>
