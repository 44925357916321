<template>
  <div class="purchase-container mt-4">
    <div class="w-full">
      <transition name="fade" mode="out-in">
        <div>
          <EmptyState
            class="empty mt-16"
            description="No purchase to display"
            v-if="!loading && !getPurchases.length"
          />
          <table id="template-table" class="w-full table-auto">
            <thead v-if="getPurchases.length">
              <tr>
                <th>Title</th>
                <th>Order Code</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <Loader v-if="loading" />

            <tbody v-else>
              <tr
                v-for="purchase in getPurchases"
                :key="purchase.id"
                class="cursor-pointer"
              >
                <td class="flex items-center">
                  {{ purchase.title | capitalize | truncate(35) }}
                </td>
                <td><span class="text-grey">#</span>{{ purchase.orderCode }}</td>
                <td>{{ purchase.amountOfOrder | formatMoney }}</td>
                <td>
                  <span
                    :class="{
                      pending: purchase.status === 'PENDING',
                      closed: purchase.status === 'CLOSED',
                      success: purchase.status === 'SUCCESSFUL',
                      processing: purchase.status === 'PAYMENT_IN_PROGRESS',
                      failed: purchase.status === 'FAILED',
                      refunded: purchase.status === 'REFUNDED',
                    }"
                    >{{ purchase.status | capitalize }}</span
                  >
                </td>
                <td>
                  {{ dayjs(purchase.createdAt).format("D MMM YYYY  |  hh.mm a") }}
                </td>
                <td>
                  <Button
                    text="View"
                    background="bg-lightPurple"
                    color="text-brandPurple"
                    class="mt-1"
                    p="py-1.5 px-6"
                    fontSize="text-xs"
                    radius="rounded-2xl"
                    fontWeight="font-semibold"
                    @click="$emit('singlePurchase', purchase)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
      <Pagination
        :handleMore="handleMore"
        :loading="paginateLoading"
        @paginate="$emit('paginate')"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Spinner from "@/UI/Spinner";
import Pagination from "@/UI/Pagination";
import Button from "@/UI/Button";
export default {
  components: {
    EmptyState: () => import("@/UI/EmptyState"),
    Loader: () => import("./Loader"),
    Spinner,
    Pagination,
    Button,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    getPurchases: {
      type: Array,
      default: () => [],
      required: true,
    },
    paginateLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    handleMore: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    dayjs() {
      return dayjs;
    },
  },
};
</script>

<style scoped>
@import "~@/assets/styles/table.css";
@import "~@/assets/styles/animation.css";
.purchase-container {
  min-height: calc(100vh - 15rem);
}
</style>
